import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Subtitle, AIWrapper, LockButton, Icon } from './style'
import { StoreProps } from '@interfaces/StoreState'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import PortalStudentProfile from '@classes/StudentProfile'
import PortalLearningPathUnit from '@classes/LearningPathUnit'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_MY_TUTOR_TOKEN } from '@queries/mytutor'
import { MyTutorTokenQueryRes } from '@interfaces/MyTutor'
import { withModal, WithModalProps } from '@components/Modal'
import ErrorContent from '@components/Modal/GenericErrorModal'
import moment from 'moment'
import { DATE_FORMAT } from '@utils/constants'
import { usePortalFeatures } from '@components/RoleBasedView'

interface AITutorLinkProps {
  status: 'locked' | 'unlocked'
  txtColor?: 'black' | 'white'
  btnColor?: 'brandSecondary' | 'brandPrimary'
  unit?: PortalLearningPathUnit
}

type LockButtonProps = Pick<AITutorLinkProps, 'status' | 'unit' | 'btnColor'>

const LockButtonWrapper: React.FC<LockButtonProps & WithModalProps> = ({ status, unit, btnColor, modal }) => {
  const intl = useIntl()
  const [opened, setOpened] = useState(false)
  const { state } = usePortalFeatures({
    configId: 'flag::Talkio-link',
  })
  const student = useSelector(
    ({ userProfile }: StoreProps) => new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo)
  )
  const learningPath = useSelector(({ activeState }: StoreProps) => activeState.learningPath)
  const opt = {
    interfaceLang: student?.student?.PortalLanguage,
    userId: student?.SFId,
    level: learningPath?.Level?.[0] || 1,
    targetLang: learningPath?.learningPath?.details?.language ?? 'en',
  }

  const showError = () => {
    modal.toggleModal(
      true,
      <ErrorContent
        onClose={() => modal.toggleModal(false)}
        title={intl.formatMessage({
          id: 'An unknown error has occurred. Please try again later.',
        })}
        description=""
        hideSupportBtn
        hideCloseBtn
      />
    )
    setOpened(true)
  }

  const [getMyTutorToken, { loading }] = useLazyQuery<MyTutorTokenQueryRes>(GET_MY_TUTOR_TOKEN, {
    onCompleted: (data) => {
      if (
        (!data.getMyTutorToken.token && !data.getMyTutorToken.expiry && !data.getMyTutorToken.link) ||
        !data.getMyTutorToken.link
      ) {
        return showError()
      }
      if (!opened && data.getMyTutorToken.link) {
        window.open(unit?.generateAITutorLink(opt, data.getMyTutorToken.link), '_self')
      }
      setOpened(true)
    },
    onError: () => !opened && showError(),
    fetchPolicy: 'no-cache',
  })

  const openAI = () => {
    const { interfaceLang, userId, level, targetLang } = opt

    if (state.allowed && unit?.AITutorLink) {
      getMyTutorToken({
        variables: {
          userId: `${student.SFId}|${learningPath.Program?.ProgramId}`,
          email: student.student.Email,
          accountExpiresAt: moment(
            moment.utc(learningPath.Program?.ContractEndDate, DATE_FORMAT.BASIC).startOf('day').toDate()
          )
            .tz(student.Timezone)
            .format(DATE_FORMAT.TZ),
          userName: student.student.Email,
          userType: 'member',
          targetLanguage: targetLang,
          interfaceLanguage: interfaceLang,
        },
      })
    } else {
      unit?.AITutorLink && window.open(unit?.generateAITutorLink(opt), '_self')
    }
  }

  useEffect(() => {
    return () => setOpened(false)
  }, [])

  if (unit?.HasContractEnded) {
    return (
      <LockButton color={btnColor}>
        <Icon color="white">
          <Lock2 size="sm" />
        </Icon>
      </LockButton>
    )
  }

  if (status === 'locked') {
    return (
      <LockButton color={btnColor}>
        <Icon color="white">
          <Lock2 size="sm" />
        </Icon>
      </LockButton>
    )
  }

  const disableButton = loading || opened
  return (
    <Button
      color="success"
      narrow
      onClick={() => {
        setOpened(false)
        openAI()
      }}
      loading={disableButton}
      disabled={disableButton}
    >
      <FormattedMessage id="Start" />
    </Button>
  )
}

const AITutorLink: React.FC<AITutorLinkProps & WithModalProps> = ({
  unit,
  status,
  txtColor = 'white',
  btnColor = 'brandPrimary',
  modal,
}) => (
  <AIWrapper>
    {unit?.AITutorLink && (
      <>
        <Subtitle color={txtColor}>
          <FormattedMessage id="Practice with your AI Tutor" />
        </Subtitle>

        <Spacer size="xs" />

        <LockButtonWrapper status={status} unit={unit} btnColor={btnColor} modal={modal} />

        <Spacer size="xs" />
      </>
    )}
  </AIWrapper>
)

export default withModal(AITutorLink)
